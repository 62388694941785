import { isEmpty, keyBy } from "lodash";
import moment from "moment";
import "moment/locale/es";
import { axios } from "../connection/ConnectionHandler";
import {
	ADD_NEW_TASK,
	CLOSE_TASK_FORM,
	CLOSE_TASK_IN_MODAL,
	DELETE_TASK,
	HIDE_ACTION_MENU,
	SET_ALL_TASKS,
	SET_DELETED_TASKS,
	SET_FETCHED_TASKS,
	SET_FINISHED_TASKS,
	SET_TOTAL_TASKS,
	SHOW_NOTIFICATION,
	TASK_FORM_TOOGLE_LOADING,
	UPDATE_TASK,
} from "../constants";
import { API } from "../constants/api";
import { formatTasks } from "./settingsActionsUtils";
moment().locale("es");

/* TASKS */
export const tasksDispatcher = (action, tasks, dispatch) => {
	if (!tasks || isEmpty(tasks)) {
		return null;
	}
	tasks.forEach((tsk) => {
		dispatch({ type: action, payload: tsk });
		// if (!isEmpty(tsk?.subTask)) {
		//     tasksDispatcher(action, tsk?.subTask, dispatch);
		// }
	});
};

export const fetchAllTasks = (finished) => {
	return async (dispatch) => {
		const response = await axios
			.get(API.tasks.list + (finished ? "/AllFinished" : ""))
			.then((response) => {
				const tasks = keyBy(response.data, "_id");
				dispatch({ type: SET_ALL_TASKS, payload: tasks });
				if (finished) {
					dispatch({ type: SET_FINISHED_TASKS, payload: true });
				} else {
					dispatch({ type: SET_FINISHED_TASKS, payload: false });
				}
				return 200;
			})
			.catch((err) => {
				dispatch({ type: SET_ALL_TASKS, payload: {} });
				return err;
			});
		return response;
	};
};

export const fetchTOTALTasks = () => {
	return async (dispatch) => {
		const response = await axios
			.get(API.tasks.total)
			.then((response) => {
				const tasks = keyBy(formatTasks(response.data), "_id");
				dispatch({ type: SET_TOTAL_TASKS, payload: tasks });
				dispatch({ type: SET_FINISHED_TASKS, payload: false });
				return 200;
			})
			.catch((err) => {
				dispatch({ type: SET_TOTAL_TASKS, payload: {} });
				return err;
			});
		return response;
	};
};

export const createTask = (data) => {
	return async (dispatch) => {
		dispatch({ type: TASK_FORM_TOOGLE_LOADING });
		const response = await axios
			.post(API.tasks.create, data)
			.then((response) => {
				const tasks = keyBy(response.data, "_id");
				dispatch({ type: SET_ALL_TASKS, payload: tasks });
				dispatch({ type: SET_FINISHED_TASKS, payload: false });
				dispatch({ type: SET_TOTAL_TASKS, payload: tasks });
				dispatch({ type: CLOSE_TASK_FORM });
				dispatch({ type: HIDE_ACTION_MENU });
				dispatch({
					type: SHOW_NOTIFICATION,
					payload: {
						show: true,
						status: "info",
						message: "Tarea creada con éxito.",
					},
				});
				return response.status;
			})
			.catch((err) => {
				dispatch({ type: TASK_FORM_TOOGLE_LOADING });
				dispatch({
					type: SHOW_NOTIFICATION,
					payload: {
						show: true,
						status: "error",
						message: "Ha ocurrido un error al crear la tarea.",
					},
				});
				return err;
			});
		return response;
	};
};

export const getTasksById = (id) => {
	return async (dispatch) => {
		dispatch({ type: TASK_FORM_TOOGLE_LOADING });
		const response = await axios
			.get(API.tasks.edit + id)
			.then((response) => {
				if (response.data) {
					dispatch({ type: ADD_NEW_TASK, payload: response.data });
				}
				return response.status;
			})
			.catch((err) => {
				dispatch({ type: TASK_FORM_TOOGLE_LOADING });
				dispatch({
					type: SHOW_NOTIFICATION,
					payload: {
						show: true,
						status: "error",
						message: "Ha ocurrido un error al crear la tarea.",
					},
				});
				return err;
			});
		return response;
	};
};

export const recover = (_id) => {
	return async (dispatch) => {
		dispatch({ type: TASK_FORM_TOOGLE_LOADING });
		const response = await axios
			.put(API.tasks.recover + _id)
			.then((response) => {
				const tasks = keyBy(response.data, "_id");
				dispatch({ type: SET_ALL_TASKS, payload: tasks });
				dispatch({ type: SET_FINISHED_TASKS, payload: false });
				dispatch({ type: SET_TOTAL_TASKS, payload: tasks });
				dispatch({ type: CLOSE_TASK_FORM });
				dispatch({ type: HIDE_ACTION_MENU });
				dispatch({
					type: SHOW_NOTIFICATION,
					payload: {
						show: true,
						status: "info",
						message: "Tarea recuperada con éxito.",
					},
				});
				return response.status;
			})
			.catch((err) => {
				dispatch({ type: TASK_FORM_TOOGLE_LOADING });
				dispatch({
					type: SHOW_NOTIFICATION,
					payload: {
						show: true,
						status: "error",
						message: "Ha ocurrido un error al recuperar la tarea.",
					},
				});
				return err;
			});
		return response;
	};
};

export const updateTask = (data) => {
	return async (dispatch) => {
		dispatch({ type: TASK_FORM_TOOGLE_LOADING });
		const response = await axios
			.put(`${API.tasks.edit}${data._id}`, data)
			.then((response) => {
				const tasks = keyBy(response.data, "_id");
				dispatch({ type: SET_ALL_TASKS, payload: tasks });
				dispatch({ type: SET_FINISHED_TASKS, payload: false });
				dispatch({ type: SET_TOTAL_TASKS, payload: tasks });
				dispatch({ type: CLOSE_TASK_FORM });
				dispatch({ type: HIDE_ACTION_MENU });
				dispatch({
					type: SHOW_NOTIFICATION,
					payload: {
						show: true,
						status: "info",
						message: "Cambios guardados.",
					},
				});
				return response.status;
			})
			.catch((err) => {
				dispatch({ type: TASK_FORM_TOOGLE_LOADING });
				return err;
			});
		return response;
	};
};

export const updateStatusTask = (data) => {
	return async (dispatch) => {
		dispatch({ type: TASK_FORM_TOOGLE_LOADING });
		const response = await axios
			.put(`${API.tasks.status}${data._id}`, data)
			.then((response) => {
				const task = response.data;
				dispatch({ type: UPDATE_TASK, payload: task });
				dispatch({ type: CLOSE_TASK_FORM });
				dispatch({ type: HIDE_ACTION_MENU });
				dispatch({
					type: SHOW_NOTIFICATION,
					payload: {
						show: true,
						status: "info",
						message: "Cambios guardados.",
					},
				});
				return response.status;
			})
			.catch((err) => {
				dispatch({ type: TASK_FORM_TOOGLE_LOADING });
				return err;
			});
		return response;
	};
};

export const deleteDateTask = (data) => {
	return async (dispatch) => {
		dispatch({ type: TASK_FORM_TOOGLE_LOADING });
		const response = await axios
			.post(`${API.tasks.deleteDate}`, { tasks: data })
			.then((response) => {
				const tasks = keyBy(response.data, "_id");
				dispatch({ type: SET_ALL_TASKS, payload: tasks });
				dispatch({ type: SET_FINISHED_TASKS, payload: false });
				dispatch({ type: SET_TOTAL_TASKS, payload: tasks });
				dispatch({ type: CLOSE_TASK_FORM });
				dispatch({ type: HIDE_ACTION_MENU });
				dispatch({
					type: SHOW_NOTIFICATION,
					payload: {
						show: true,
						status: "info",
						message: "Cambios guardados.",
					},
				});
				return response.status;
			})
			.catch((err) => {
				dispatch({ type: TASK_FORM_TOOGLE_LOADING });
				return err;
			});
		return response;
	};
};

export const archiveDateTask = (data) => {
	return async (dispatch) => {
		dispatch({ type: TASK_FORM_TOOGLE_LOADING });
		const response = await axios
			.post(`${API.tasks.archiveDate}`, { tasks: data })
			.then((response) => {
				const tasks = keyBy(response.data, "_id");
				dispatch({ type: SET_ALL_TASKS, payload: tasks });
				dispatch({ type: SET_FINISHED_TASKS, payload: false });
				dispatch({ type: SET_TOTAL_TASKS, payload: tasks });
				dispatch({ type: CLOSE_TASK_FORM });
				dispatch({ type: HIDE_ACTION_MENU });
				dispatch({
					type: SHOW_NOTIFICATION,
					payload: {
						show: true,
						status: "info",
						message: "Cambios guardados.",
					},
				});
				return response.status;
			})
			.catch((err) => {
				dispatch({ type: TASK_FORM_TOOGLE_LOADING });
				return err;
			});
		return response;
	};
};

export const deleteFile = (id) => {
	return async (dispatch) => {
		dispatch({ type: TASK_FORM_TOOGLE_LOADING });
		const response = await axios
			.delete(`${API.files.delete}${id}`)
			.then((response) => {
				return response.status;
			})
			.catch((err) => {
				dispatch({ type: TASK_FORM_TOOGLE_LOADING });
				return err;
			});
		return response;
	};
};

export const createChangeDateTask = (data) => {
	return async (dispatch) => {
		dispatch({ type: TASK_FORM_TOOGLE_LOADING });
		const response = await axios
			.post(`${API.changeDateTask.create}`, data)
			.then((response) => {
				dispatch({ type: CLOSE_TASK_FORM });
				dispatch({ type: HIDE_ACTION_MENU });
				dispatch({
					type: SHOW_NOTIFICATION,
					payload: {
						show: true,
						status: "info",
						message: "Cambios guardados.",
					},
				});
				return response.status;
			})
			.catch((err) => {
				dispatch({ type: TASK_FORM_TOOGLE_LOADING });
				return err;
			});
		return response;
	};
};

export const editChangeDateTask = (data) => {
	return async (dispatch) => {
		dispatch({ type: TASK_FORM_TOOGLE_LOADING });
		const response = await axios
			.put(`${API.changeDateTask.edit}${data._id}`, { accepted: data.accepted })
			.then((response) => {
				tasksDispatcher(UPDATE_TASK, response.data, dispatch);
				dispatch({ type: CLOSE_TASK_FORM });
				dispatch({ type: HIDE_ACTION_MENU });
				dispatch({
					type: SHOW_NOTIFICATION,
					payload: {
						show: true,
						status: "info",
						message: "Cambios guardados.",
					},
				});
				return response.status;
			})
			.catch((err) => {
				dispatch({ type: TASK_FORM_TOOGLE_LOADING });
				return err;
			});
		return response;
	};
};

export const attachAudio = (id, audio) => {
	return async (dispatch) => {
		dispatch({ type: TASK_FORM_TOOGLE_LOADING });
		const response = await axios
			.put(`${API.tasks.audio}${id}`, { audio })
			.then((response) => {
				const tasks = keyBy(response.data, "_id");
				dispatch({ type: SET_ALL_TASKS, payload: tasks });
				dispatch({ type: SET_FINISHED_TASKS, payload: false });
				dispatch({ type: SET_TOTAL_TASKS, payload: tasks });
				dispatch({ type: CLOSE_TASK_FORM });
				dispatch({ type: HIDE_ACTION_MENU });
				dispatch({
					type: SHOW_NOTIFICATION,
					payload: { show: true, status: "info", message: "Audio guardado." },
				});
				return response.status;
			})
			.catch((err) => {
				dispatch({ type: TASK_FORM_TOOGLE_LOADING });
				return err;
			});
		return response;
	};
};

export const attachDocument = (id, document) => {
	return async (dispatch) => {
		dispatch({ type: TASK_FORM_TOOGLE_LOADING });
		const response = await axios
			.put(`${API.tasks.document}${id}`, document)
			.then((response) => {
				const tasks = keyBy(response.data, "_id");
				dispatch({ type: SET_ALL_TASKS, payload: tasks });
				dispatch({ type: SET_FINISHED_TASKS, payload: false });
				dispatch({ type: SET_TOTAL_TASKS, payload: tasks });
				dispatch({
					type: SHOW_NOTIFICATION,
					payload: {
						show: true,
						status: "info",
						message: "Documento guardado.",
					},
				});
				return response.status;
			})
			.catch((err) => {
				dispatch({ type: TASK_FORM_TOOGLE_LOADING });
				return err;
			});
		return response;
	};
};

export const deleteTask = (id) => {
	return async (dispatch) => {
		const response = await axios
			.delete(`${API.tasks.delete}${id}`)
			.then((response) => {
				dispatch({ type: DELETE_TASK, payload: id });
				dispatch({ type: CLOSE_TASK_FORM });
				dispatch({ type: CLOSE_TASK_IN_MODAL, payload: id });
				dispatch({ type: HIDE_ACTION_MENU });
				dispatch({
					type: SHOW_NOTIFICATION,
					payload: {
						show: true,
						status: "warning",
						message: "Tarea eliminada.",
					},
				});
				return response.status;
			})
			.catch((err) => err);
		return response;
	};
};

export const fetchDeletedTasks = () => {
	return async (dispatch) => {
		const response = await axios
			.get(API.tasks.deletedTasks)
			.then((response) => {
				const tasks = keyBy(response.data, "_id");
				dispatch({ type: SET_DELETED_TASKS, payload: tasks });
				return 200;
			})
			.catch((err) => {
				dispatch({ type: SET_DELETED_TASKS, payload: {} });
				return err;
			});
		return response;
	};
};

export const fetchArchivedTasks = () => {
	return async (dispatch) => {
		const response = await axios
			.get(API.tasks.archivedTasks)
			.then((response) => {
				const tasks = keyBy(response.data, "_id");
				dispatch({ type: SET_FETCHED_TASKS, payload: tasks });
				return 200;
			})
			.catch((err) => {
				dispatch({ type: SET_FETCHED_TASKS, payload: {} });
				return err;
			});
		return response;
	};
};
